import React, { FC } from 'react';
import { IImageCardProps } from '@shared/types';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import './ImageCard.scss';
import Button from 'common/Button';

const ImageCard: FC<IImageCardProps> = ({ description, image, link, isImageLink }) => {
  const previewImage = image?.[0]?.properties?.image;

  return (
    <div className="image-card">
      {previewImage && (
        <div className="image-card__image-container">
          {link?.[0] && isImageLink ? (
            <Button
              to={link[0].url}
              ariaLabel={link[0].name}
              target={link[0].target}
              variant="link"
            >
              <GatsbyImage image={previewImage} objectFit="contain" />
            </Button>
          ) : (
            <GatsbyImage image={previewImage} objectFit="contain" />
          )}
        </div>
      )}
      <DangerouslySetInnerHtml className="image-card__description" html={description} />
      {link?.[0] && !isImageLink ? (
        <Button
          classes="image-card__link"
          to={link[0].url}
          target={link[0].target}
          variant="secondary-link"
        >
          {link[0].name}
        </Button>
      ) : null}
    </div>
  );
};

export default ImageCard;
