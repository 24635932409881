import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import './Step.scss';
import classNames from 'classnames';
import { IStep } from './models';

const Step: FC<IStep> = ({
  properties: { description, link, image, imageAutoHeight },
  isHorizontal = false,
}) => (
  <div
    className={classNames('step', {
      'step--horizontal': isHorizontal,
      'step--image-auto': imageAutoHeight,
    })}
    data-testid="step"
  >
    {image?.length ? (
      <div
        className={classNames('step__image', {
          'step__image--horizontal': isHorizontal,
        })}
      >
        <GatsbyImage
          image={image[0].properties.image}
          className="step__image-img"
          alt={image[0].properties.imageAlt}
          objectFit={imageAutoHeight ? 'contain' : 'cover'}
        />
      </div>
    ) : null}
    {description ? (
      <div
        className={classNames('step__description', {
          'step__description--horizontal': isHorizontal,
        })}
      >
        <DangerouslySetInnerHtml className="step__description-text" html={description} />

        {link?.length ? (
          <Button
            to={link[0].properties.link[0].url}
            ariaLabel={link[0].properties.ariaLabel}
            variant="secondary-link"
            iconSuffix="chevron-right"
          >
            {link[0].properties.link[0].name}
          </Button>
        ) : null}
      </div>
    ) : null}
  </div>
);

export default Step;
