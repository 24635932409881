import React, { FC } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import ListPlusAccordion from 'common/ListPlusAccordion';
import { IWideBannerProps } from './model';
import './WideBanner.scss';

const WideBanner: FC<IWideBannerProps> = ({
  image,
  description = '',
  cta = [],
  alignImage = ['left'],
  gridList,
  color,
  accordion,
}) => {
  const bannerImage = image?.[0]?.properties;

  const isTransparentTheme = color?.label === 'transparent';

  return (
    <div
      className={classNames('wide-banner', {
        'wide-banner--reverse': alignImage?.[0] === 'left',
        [`wide-banner--bg-${color?.label}`]: color?.label,
        'wide-banner--dark-theme': color?.label === 'blue' || isTransparentTheme,
        'wide-banner--transparent-theme': isTransparentTheme,
      })}
    >
      <div
        className={classNames('wide-banner__info', {
          'wide-banner__info--start': !!gridList?.length,
          'wide-banner__info--dark-theme': color?.label === 'blue' || isTransparentTheme,
        })}
      >
        {description ? (
          <DangerouslySetInnerHtml className="wide-banner__description" html={description} />
        ) : null}
        {gridList?.length ? (
          <ListPlusAccordion {...{ ...gridList[0].properties, accordion }} />
        ) : null}
        {cta?.length > 0 && (
          <div className="wide-banner__buttons">
            {cta.map(({ properties: { link, ariaLabel = '' } }, idx) => {
              const linkItem = link?.[0];

              return (
                <Button
                  to={
                    linkItem?.queryString
                      ? `${linkItem?.url}${linkItem?.queryString}`
                      : linkItem?.url
                  }
                  key={linkItem?.name}
                  ariaLabel={ariaLabel}
                  variant={idx === 0 ? 'primary-link' : 'secondary-link'}
                  iconSuffix="chevron-right"
                  target={linkItem?.target}
                >
                  {linkItem?.name}
                </Button>
              );
            })}
          </div>
        )}
      </div>
      {bannerImage?.image && (
        <div className="wide-banner__image">
          <GatsbyImage
            image={bannerImage.image}
            alt={bannerImage.imageAlt}
            isLazyLoading={false}
            fadeIn={false}
            useFocalPoint
          />
        </div>
      )}
    </div>
  );
};

export const query = graphql`
  fragment FragmentWideBanner on TInfoBannerWide {
    properties {
      showInMenu
      anchorName
      alignImage
      cta {
        properties {
          ariaLabel
          link {
            name
            target
            url
            queryString
          }
        }
      }
      color {
        label
      }
      description
      image {
        properties {
          imageAlt
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      accordion {
        properties {
          ariaLabel
          content {
            properties {
              text
            }
          }
          label
        }
      }
      gridList {
        properties {
          infoList {
            properties {
              description
              icon
              iconAlignment
            }
          }
        }
      }
    }
    structure
  }
`;

export default WideBanner;
