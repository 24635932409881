import React, { FC } from 'react';
import ControlledAccordion from 'common/ControlledAccordion';
import IconPlusTextGrid from 'common/IconPlusTextGrid';
import './ListPlusAccordion.scss';
import { graphql } from 'gatsby';
import { IListAndAccordionItem } from '@shared/types/umbraco';

const ListPlusAccordion: FC<IListAndAccordionItem> = ({
  infoList,
  accordion,
  columnsCount,
  btnTitle,
  ariaMore,
}) => (
  <div data-testid="list" className="list-flex">
    <div className="list-container">
      {infoList && <IconPlusTextGrid {...{ infoList, columnsCount, btnTitle, ariaMore }} />}
      {accordion?.length ? <ControlledAccordion items={accordion} /> : null}
    </div>
  </div>
);
export const query = graphql`
  fragment FragmentListPlusAccordionProps on TListAndAccordion {
    properties {
      accordion {
        properties {
          ariaLabel
          content {
            properties {
              text
            }
          }
          label
        }
      }
      infoList {
        properties {
          description
          icon
        }
      }
      title
    }
  }
`;

export default ListPlusAccordion;
