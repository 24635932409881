import React, { FC } from 'react';
import { parseBoolean } from 'utils/parseBoolean';
import classnames from 'classnames';
import Step from 'components/Step/Step';
import { UmbracoStepList } from '@shared/types/umbraco/compositions/stepList';
import './StepList.scss';

const StepList: FC<UmbracoStepList.IStructure> = ({
  properties: { steps, showNumber, isHorizontal = '0' },
}) => {
  const stepsCSSClassNames = classnames('step-list', {
    'step-list--with-numbers': parseBoolean(showNumber),
    'step-list--horizontal': parseBoolean(isHorizontal),
  });

  return steps?.length ? (
    <div className={stepsCSSClassNames} data-testid="step-list">
      {steps.map((step, idx) => (
        <Step
          key={`${step.properties.description.length}${idx}`}
          {...step}
          isHorizontal={parseBoolean(isHorizontal)}
        />
      ))}
    </div>
  ) : null;
};

export default StepList;
